:root {
  /* usage: var(--wise-primary) */
  --normal-white: #ffffff;
  --normal-black: #000000;
  --wise-primary: #ec7112;
  --wise-black: #545b64;

  --wise-red: #e14c3b;
  --wise-green: #218d03;
  --wise-grey: #ffffff4d;
  --wise-blue: #0073bb;

  --dark-primary: #e07523;
  --dark-cancel: #cf3c2c;
  --dark-submit: #137c3b;

  --input-border: #aab7b8;
  --focus-border: #02a1c9;

  --wise-sidebar-text: #879596;
  --wise-darkblue: #205181;
  --wise-boxShadow: #98a6b3;
  --background: #f2f3f3;

  --wise-navbar-hover-blue: #3572b0;

  --wise-box-shadow-grey: #8e8e8e;

  --process-red: #df2a02;
  --process-green: #4bb102;
  --process-yellow: #ecb201dc;
  --navbar-black: #222e3e;
  --process-grey: #dadada;

  --wise-tcs-publish-yes: #87ceff;
  --wise-tcs-publish-no: #ffc1c1;
}

.lens-ui-navbar {
  z-index: 2;
  align-items: center;
  border: none;
  border-radius: 0px;
  background-color: var(--navbar-black) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 50px;
  font-size: 0.97rem;
  font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.6px;
}

.lens-ui-navbar-menu-ul {
  display: flex !important;
  align-items: center !important;
  border: none;
  border-radius: 0px;
  background-color: var(--navbar-black) !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-left: 5% !important;
  line-height: 1.6 !important;
  font-size: 0.97rem;
}

.lens-ui-navbar-second-ul {
  display: flex !important;
  align-items: center !important;
  border: none;
  border-radius: 0px;
  background-color: var(--navbar-black) !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-right: 5px !important;
  line-height: 1.6 !important;
  font-size: 0.97rem;
}

.lens-ui-navbar-brand-icon {
  color: white;
  font-size: 22px;
}

.lens-ui-navbar-brand-text {
  margin-left: 10px !important;
  color: white;
  font-size: 20px;
  line-height: 20px;
}

.lens-ui-navbar-subtitle-text {
  margin-left: 60px !important;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500 !important;
}

.lens-ui-navbar-active {
  color: #ec7112 !important;
}

.lens-ui-navbar-nav-item {
  padding: 0px !important;
  margin-left: 5px !important;
}

.lens-ui-navbar-nav-item-two {
  padding: 0px !important;
  margin-left: 0px !important;
}

.lens-ui-navbar-nav-link,
.lens-ui-navbar-non-link {
  color: white !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.lens-ui-navbar-nav-link:focus,
.lens-ui-navbar-nav-link:hover {
  text-decoration: none !important;
  color: #ec7112 !important;
}

.lens-ui-navbar-nav-item:hover .dropdown-menu, .lens-ui-navbar-nav-item-two:hover .dropdown-two{
  display: block;
}

.dropdown-menu {
  z-index: 2;
  padding-top: 0 !important;
  display: none;
  margin-top: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.dropdown-item {
  outline: none !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--wise-primary) !important;
  background-color: var(--navbar-black);
}

.lens-ur-navbar-dropdown-item {
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 5px 24px !important;
  color: var(--normal-white) !important;
  background-color: var(--navbar-black) !important;
  text-decoration: none !important;
}

.lens-ui-navbar-nav-button-item {
  cursor: pointer;
}


.dropdown-two {
  cursor: pointer;
  position: absolute;
  top: 0px;
  padding-left: 100%;
  z-index: 2;
  padding-top: 0 !important;
  display: none;
  margin-top: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.lens-ui-sidebar-tree-view-arrow {
  cursor: pointer;
  /* margin-right: 2px; */
  display: flex;
  align-items: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
  margin-right: 5px;
}

.lens-ui-sidebar-treeview-arrow-hidden {
  visibility: hidden;
}

.arrow-button {
  text-align: center;
  vertical-align: middle;
  /* padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 1.5px !important; */
  background-color: transparent;
  border: none;
}

.lens-ui-sidebar-tree-view-arrow-right {
  color: hsl(184, 7%, 56%);
  font-size: 18px;
  font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
  font-weight: 700;
}

.lens-ui-sidebar-tree-view-arrow-right:hover {
  color: black;
}

.lens-ui-sidebar-tree-view-arrow-down {
  color: #879596;
  font-size: 18px;
  font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
  font-weight: 700;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
}

.lens-ui-sidebar-tree-view-arrow-down:hover {
  color: black;
}

.lens-ui-sidebar-treenode-label-wrapper {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 3px;
  line-height: 18px;
}

.lens-ui-sidebar-treenode-label {
  font: Helvetica, Arial, sans-serif;
  color: var(--wise-sidebar-text) !important;
  font-family: Amazon-Ember-Rg, Helvetica Neue, Roboto, Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none !important;
}

.lens-ui-sidebar-treenode-label-link:hover {
  cursor: pointer;
}

.lens-ui-sidebar-treenode-label-searched {
  color: red !important;
}

.lens-ui-sidebar-treenode-label-selected {
  color: #ec5f08 !important;
}

.lens-ui-sidebar-treenode-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.len-ui-sidebar-treenode-indent {
  margin-left: 15px;
}

.lens-ui-sidebar-collapse {
  display: none;
}

.lens-ui-sidebar-general-wrapper {
  margin-left: 10px;
  margin-right: 10px;
}

/* ************************************************************************************* */

/* tree view */
.lens-ui-sidebar-treeview-wrapper {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

/* ************************************************************************************* */

/* title*/
.lens-ui-sidebar-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

/* ************************************************************************************* */

/* search box */
.lens-ui-sidebar-searchbox-wrapper {
  margin: 20px 10px 20px 10px;
}

.lens-ui-sidebar-searchbox {
  display: inline-flex;
  flex: 0 auto;
  position: relative;
  border: 1px solid var(--input-border);
  width: 100%;
  font-size: 0.9rem;
  line-height: 30px;
  overflow: hidden;
  border-radius: 2px;
  background-color: white;
}

.lens-ui-sidebar-searchbox:focus-within {
  border: 2px solid var(--focus-border);
}

.lens-ui-sidebar-searchbox input {
  min-width: 0;
  width: 100%;
  padding: 0px 10px;
  font-size: 14px !important;
  line-height: 30px !important;
  outline: none;
  border: 0.5px var(--input-border);
  border-radius: 2px;
  color: var(--wise-black);
}

.lens-ui-sidebar-searchbox label {
  font-size: 14px;
}

input::placeholder {
  font-size: 14px !important;
  font-style: italic;
}

.lens-ui-search-btn {
  font-weight: 500;
  letter-spacing: 0.25px;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 1px !important;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: button;
  outline: none !important;
  border: none;
  background-color: transparent;
}

.lens-ui-search-btn:active {
  transform: translateY(1px);
}

/* ************************************************************************************* */

/* toggle button*/
.lens-ui-sidebar-toggle-button-wrapper {
  position: absolute;
  top: 6px;
  right: 10px;
}

.lens-ui-sidebar-toggle-button-wrapper-collapse {
  position: absolute;
  top: 20px;
  left: 7px;
}

.lens-ui-sidebar-toggle-button {
  width: 25px !important;
  height: 20px !important;
  line-height: 20px;
  color: grey;
  background-color: transparent !important;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  outline: none !important;
  border: none;
}

.lens-ui-sidebar-toggle-button:active {
  transform: translateY(1px);
}

.lens-ui-sidebar-toggle-button:hover {
  color: black;
}

.lens-ui-export-button {
  color: var(--wise-primary);
  font-size: 25;
  cursor: pointer;
  position: relative;
  width: 25px;
}

.lens-ui-export-button:hover {
  color: var(--dark-primary);
}

.lens-ui-export-button-spinner {
  font-size: 10;
  position: absolute;
  top: 0px;
  left: -3px;
}

.lens-ui-export-button-disable {
  pointer-events: none;
  color: lightgrey;
}

.lens-about-us-container {
  margin: 50px 20px;
}

.lens-about-us-main {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 28 36 / 30%), 1px 1px 1px 0 rgb(0 28 36 / 15%),
    -1px 1px 1px 0 rgb(0 28 36 / 15%);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3),
    1px 1px 1px 0 var(--awsui-color-shadow-side, rgba(0, 28, 36, 0.15)),
    -1px 1px 1px 0 var(--awsui-color-shadow-side, rgba(0, 28, 36, 0.15));
  border-top: 1px solid #eaeded;
  border-top: 1px solid #eaeded;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 2rem;
  word-wrap: break-word;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.lens-about-us-text-field {
  font-family: Open Sans tahoma, verdana, arial, sans-serif;
  font-size: "22px";
  /* text-align: center !important; */
  margin-left: 12%;
  margin-right: 12%;
}
.lens-about-us-cards {
  /* text-align: center;
  margin-left: 27%;
  margin-right: 27%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.lens-about-us-text-field-title {
  text-align: center;
}

.lens-about-us-team-name {
  font-weight: 600;
  color: #ec7310;
}

.lens-about-us-image {
  position: absolute;
  top: -50px;
  right: -50px;
}

.lens-about-us-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 28 36 / 30%), 1px 1px 1px 0 rgb(0 28 36 / 15%),
    -1px 1px 1px 0 rgb(0 28 36 / 15%);
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3),
    1px 1px 1px 0 var(--awsui-color-shadow-side, rgba(0, 28, 36, 0.15)),
    -1px 1px 1px 0 var(--awsui-color-shadow-side, rgba(0, 28, 36, 0.15));
  border-top: 1px solid #eaeded;
  border-top: 1px solid #eaeded;
  border-radius: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  font-size: 14px;
  width: 250px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.lens-about-us-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.lens-about-us-card-title {
  margin-bottom: 0.75rem;
}

.lens-footer {
  background-color: var(--navbar-black) !important;
  height: 50px;
  padding: 0 10rem;
}

.lens-footer-content {
  display: flex;
  flex-direction: row;
  line-height: 50px;
  color: #d4dada;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400px;
}

.lens-footer-content-item {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

.lens-footer-content-item > a {
  color: #d4dada;
  font-size: 14px;
  font-weight: 400px;
  text-decoration: none;
}


/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_grid_14yj0_1r8jg_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #16191f;
  color: var(--color-text-body-default-7m2b3r, #16191f);
  font-weight: 400;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display: flex;
  flex-wrap: wrap;
  margin: calc(20px / -2);
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  pointer-events: none;
}
.awsui_grid_14yj0_1r8jg_93.awsui_no-gutters_14yj0_1r8jg_107:not(#\9) {
  margin: 0;
}

.awsui_grid-column_14yj0_1r8jg_111:not(#\9) {
  box-sizing: border-box;
  position: relative;
  padding: calc(20px / 2);
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  display: flex;
}
.awsui_grid_14yj0_1r8jg_93.awsui_no-gutters_14yj0_1r8jg_107 > .awsui_grid-column_14yj0_1r8jg_111:not(#\9) {
  padding: 0;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-1_14yj0_1r8jg_120:not(#\9) {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-1_14yj0_1r8jg_124:not(#\9) {
  left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-1_14yj0_1r8jg_127:not(#\9) {
  right: 8.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-2_14yj0_1r8jg_130:not(#\9) {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-2_14yj0_1r8jg_134:not(#\9) {
  left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-2_14yj0_1r8jg_137:not(#\9) {
  right: 16.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-3_14yj0_1r8jg_140:not(#\9) {
  flex: 0 0 25%;
  max-width: 25%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-3_14yj0_1r8jg_144:not(#\9) {
  left: 25%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-3_14yj0_1r8jg_147:not(#\9) {
  right: 25%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-4_14yj0_1r8jg_150:not(#\9) {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-4_14yj0_1r8jg_154:not(#\9) {
  left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-4_14yj0_1r8jg_157:not(#\9) {
  right: 33.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-5_14yj0_1r8jg_160:not(#\9) {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-5_14yj0_1r8jg_164:not(#\9) {
  left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-5_14yj0_1r8jg_167:not(#\9) {
  right: 41.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-6_14yj0_1r8jg_170:not(#\9) {
  flex: 0 0 50%;
  max-width: 50%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-6_14yj0_1r8jg_174:not(#\9) {
  left: 50%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-6_14yj0_1r8jg_177:not(#\9) {
  right: 50%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-7_14yj0_1r8jg_180:not(#\9) {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-7_14yj0_1r8jg_184:not(#\9) {
  left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-7_14yj0_1r8jg_187:not(#\9) {
  right: 58.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-8_14yj0_1r8jg_190:not(#\9) {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-8_14yj0_1r8jg_194:not(#\9) {
  left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-8_14yj0_1r8jg_197:not(#\9) {
  right: 66.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-9_14yj0_1r8jg_200:not(#\9) {
  flex: 0 0 75%;
  max-width: 75%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-9_14yj0_1r8jg_204:not(#\9) {
  left: 75%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-9_14yj0_1r8jg_207:not(#\9) {
  right: 75%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-10_14yj0_1r8jg_210:not(#\9) {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-10_14yj0_1r8jg_214:not(#\9) {
  left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-10_14yj0_1r8jg_217:not(#\9) {
  right: 83.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-11_14yj0_1r8jg_220:not(#\9) {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-11_14yj0_1r8jg_224:not(#\9) {
  left: 91.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-11_14yj0_1r8jg_227:not(#\9) {
  right: 91.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_colspan-12_14yj0_1r8jg_230:not(#\9) {
  flex: 0 0 100%;
  max-width: 100%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-12_14yj0_1r8jg_234:not(#\9) {
  left: 100%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-12_14yj0_1r8jg_237:not(#\9) {
  right: 100%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_push-0_14yj0_1r8jg_240:not(#\9) {
  left: auto;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_pull-0_14yj0_1r8jg_243:not(#\9) {
  right: auto;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-1_14yj0_1r8jg_246:not(#\9) {
  margin-left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-2_14yj0_1r8jg_249:not(#\9) {
  margin-left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-3_14yj0_1r8jg_252:not(#\9) {
  margin-left: 25%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-4_14yj0_1r8jg_255:not(#\9) {
  margin-left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-5_14yj0_1r8jg_258:not(#\9) {
  margin-left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-6_14yj0_1r8jg_261:not(#\9) {
  margin-left: 50%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-7_14yj0_1r8jg_264:not(#\9) {
  margin-left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-8_14yj0_1r8jg_267:not(#\9) {
  margin-left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-9_14yj0_1r8jg_270:not(#\9) {
  margin-left: 75%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-10_14yj0_1r8jg_273:not(#\9) {
  margin-left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1r8jg_111.awsui_offset-11_14yj0_1r8jg_276:not(#\9) {
  margin-left: 91.6666666667%;
}

.awsui_restore-pointer-events_14yj0_1r8jg_280:not(#\9) {
  pointer-events: auto;
  width: 100%;
}